import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { AuthApiService, AuthData, AuthDataBE } from '@ess-front/shared';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MagicLinkApiService {
  private readonly API_URL = environment.apiEnv + 'api/rest-auth/magic-link/';

  constructor(
    private authApiService: AuthApiService,
    private httpClient: HttpClient,
  ) {}

  sendEmail$(email: string, redirectUrl: string | null): Observable<null> {
    const body = redirectUrl ? { email, redirect_url: this.getBERedirectUrl(redirectUrl) } : { email };
    return this.httpClient.post<null>(this.API_URL, body);
  }

  verifyToken$(email: string, token: string): Observable<AuthData> {
    const params = new HttpParams().set('token', token).set('email', email);
    return this.httpClient
      .get<AuthDataBE>(this.API_URL, { params })
      .pipe(map(authDataBE => this.authApiService.transformIntoAuthData(authDataBE)));
  }

  private getBERedirectUrl(redirectUrl: string): string {
    const redirectUrlWithoutSlash = redirectUrl.startsWith('/') ? redirectUrl.slice(1) : redirectUrl;
    return `${environment.apiEnv}${redirectUrlWithoutSlash}`;
  }
}
