import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, take } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthData, AuthStorageService } from '@ess-front/shared';
import { MagicLinkApiService } from '@api/magic-link-api.service';
import { UserDomainService } from '@domain/user-domain.service';

@Injectable({ providedIn: 'root' })
export class MagicLinkDomainService {
  private readonly MEMBERS_AREA_URL = '/members-area';

  constructor(
    private authStorageService: AuthStorageService,
    private magicLinkApiService: MagicLinkApiService,
    private userDomainService: UserDomainService,
  ) {}

  sendEmail(email: string, redirectUrl: string | null): void {
    this.magicLinkApiService.sendEmail$(email, redirectUrl).pipe(take(1)).subscribe();
  }

  verifyToken$(email: string, token: string): Observable<AuthData> {
    return this.magicLinkApiService.verifyToken$(email, token).pipe(
      tap(authData => this.authStorageService.saveAuthData(authData, false)),
      switchMap(authData => forkJoin([this.userDomainService.getUser$(), of(authData)])),
      map(([, authData]) => (authData.redirectUrl ? authData : { ...authData, redirectUrl: this.MEMBERS_AREA_URL })),
    );
  }
}
